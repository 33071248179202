import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from '../@services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserType } from 'src/app/@core/modals/user-type';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public credentials: any = {};
    @ViewChild('loginForm', { static: true }) loginForm: NgForm;

    constructor(private messageService: MessageService, private authService: AuthService, private router: Router) {
    }

    onSubmit() {
        this.authService.login(this.credentials, UserType.ADMIN).subscribe((data) => {
            this.showMessage('success', 'Başarılı', 'Anasayfaya yönlendiriliyorsunuz.');
            this.loginForm.resetForm();
            this.router.navigate(['/pages']);
        }, (err) => {
            this.showMessage('error', err.error.code, err.error.message);
        });
    }

    private showMessage(severity, summary, detail) {
        this.messageService.clear();
        this.messageService.add({
            severity,
            summary,
            detail
        });
    }

    ngOnInit() {
    }
}
